import React, { useState } from 'react';
import { NumericFormat } from 'react-number-format'; // Updated import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'reactstrap';

const InputGroup = (props) => {
    const { id, label, value, setValue, tooltip, tooltipLink, min, max, allowNegative, decimalScale, prefix, thousandSeparator, inputMode } = props;
    const [tooltipOpen, setTooltipOpen] = useState({});
    const toggleTooltip = (name) => {
        setTooltipOpen({ ...tooltipOpen, [name]: !tooltipOpen[name] });
    };

    const handleValueChange = (values) => {
        const { floatValue } = values;
        setValue(floatValue);
    };

    return (
        <div className="mb-3">
            <label htmlFor={id} className="form-label">
                {label} &nbsp;&nbsp;&nbsp;
                {value < min && <><FontAwesomeIcon icon={faExclamationTriangle} className="text-danger" /><b className="text-danger"> (Min value is: {min})</b></>}
                {value > max && <><FontAwesomeIcon icon={faExclamationTriangle} className="text-danger" /><b className="text-danger"> (Max value is: {max})</b></>}
            </label>
            <div className="input-group">
                <NumericFormat
                    className="form-control form-control-lg"
                    id={id}
                    // inputMode={inputMode}
                    value={value}
                    onValueChange={handleValueChange}
                    thousandSeparator={thousandSeparator}
                    prefix={prefix}
                    decimalScale={decimalScale}
                    fixedDecimalScale={false}
                    min={min}
                    max={max}
                    allowNegative={allowNegative}
                    getInputRef={el => el && (el.inputMode = inputMode)}
                    onFocus={(e) => e.target.select()}
                    onBlur={(e) => {
                        const newValue = parseFloat(e.target.value.replace(/[^0-9-.]/g, ''));
                        if (newValue < min) {
                            setValue(min);
                        } else if (newValue > max) {
                            setValue(max);
                        }
                    }}
                />

                <span className="input-group-text" id={`${id}Tooltip`} style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon icon={faQuestionCircle} size="lg" color='#444' />
                </span>
                <Tooltip
                    placement="top"
                    isOpen={tooltipOpen[id]}
                    target={`${id}Tooltip`}
                    toggle={() => toggleTooltip(id)}
                    autohide={false}
                >
                    {tooltip} &nbsp;
                    {tooltipLink && (
                        <a href="#service-section"
                            onClick={() => global.serviceShowCustom(tooltipLink)}>Learn more
                        </a>
                    )}
                </Tooltip>

            </div>
        </div>
    );
};

export default InputGroup;
