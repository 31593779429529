import React from 'react'
import Home from './views/home'
import { Provider, ErrorBoundary } from '@rollbar/react';

const rollbarConfig = {
  accessToken: 'db4a317b95a541a097972cd75afb8ab6',
  environment: 'prod',
};

const App = () => {
  return (
    <div className="App">
      <Provider config={rollbarConfig}>
        <ErrorBoundary>
          <Home />
        </ErrorBoundary>
      </Provider>
    </div>
  )
}

export default App
