import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'reactstrap';

const SelectInput = (props) => {
    const { id, label, value, setValue, options, tooltip } = props;
    const [tooltipOpen, setTooltipOpen] = useState({});

    const toggleTooltip = (name) => {
        setTooltipOpen({ ...tooltipOpen, [name]: !tooltipOpen[name] });
    };

    return (
        <div className="mb-3">
            <label htmlFor={id} className="form-label">
                {label}
            </label>
            <div className="input-group">
                <select id={id} value={value}
                    onChange={e => setValue(e.target.value)}
                    className="form-control form-control-lg">
                    {options.map((option, index) => (
                        <option key={index} value={option.value}>{option.label}</option>
                    ))}
                </select>
                <span className="input-group-text" id={`${id}Tooltip`} style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon icon={faQuestionCircle} size="lg" color='#444' />
                </span>
                <Tooltip
                    placement="top"
                    isOpen={tooltipOpen[id]}
                    target={`${id}Tooltip`}
                    toggle={() => toggleTooltip(id)}
                >
                    {tooltip}
                </Tooltip>
            </div>
        </div>
    );
};

export default SelectInput;