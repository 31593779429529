const StartDate = {
    DEFAULT: new Date().getFullYear(),
    MIN: 30,
    MAX: 30,
    STEP: 1
};

const Investment = {
    DEFAULT: 100000,
    MIN: 1,
    MAX: 100000000,
    STEP: 1000
};

const Period = {
    DEFAULT: 10,
    MIN: 1,
    MAX: 30,
    STEP: 1
};

const AnnualReturn = {
    DEFAULT: 7.36,
    MIN: 0,
    MAX: 50,
    STEP: 0.1
};

const StDev = {
    DEFAULT: 9.72,
    MIN: 0,
    MAX: 50,
    STEP: 0.1
};

const Inflation = {
    DEFAULT: 2.50,
    MIN: 0,
    MAX: 20,
    STEP: 0.1
};

const CashFlow = {
    DEFAULT: 5000,
    MIN: -10000000,
    MAX: 10000000,
    STEP: 1000
};

const DateFactor = {
    YEAR: 1,
    QUARTER: 4,
    MONTH: 12
}

const Constants = {
    StartDate,
    Investment,
    Periods: Period,
    AnnualReturn,
    StDev,
    Inflation,
    CashFlow,
    DateFactor,
};

export default Constants;
