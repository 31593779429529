import jStat from 'jstat';

const SD_PLUS_1 = 0.84;
const SD_PLUS_2 = 0.975;
const SD_MINUS_1 = 0.16;
const SD_MINUS_2 = 0.025;

function performCalculation(initialInvest, startDate, periods, returnExpec, sDev, inflation, cashFlow, dateFactor, rand) {
    periods *= dateFactor;

    const preCashFlow = preCashFlowCalc(periods, initialInvest, sDev, returnExpec, inflation, dateFactor);

    const returns = returnsCalc(periods, preCashFlow, initialInvest);

    const postCashFlow = postCashFlowCalc(periods, returns, initialInvest, cashFlow);

    const simulatedMedian = simulatedMedianCalc(dateFactor, returnExpec, sDev, inflation, periods, cashFlow, initialInvest, rand)

    const chartData = chartDataCalc(startDate, initialInvest, periods, postCashFlow, cashFlow, dateFactor, simulatedMedian);

    if (chartData.length > 0) {
        const finalData = chartData[chartData.length - 1];
        return { chartData, finalData };
    } else {
        return chartData
    }
}

function preCashFlowFormula(initialInvest, continuousMean, sDev, sig, i, dateFactor) {
    const factor = i * (1 / dateFactor)
    return initialInvest * Math.exp(continuousMean * factor - 0.5 * sDev * sDev * factor + sig * sDev * Math.sqrt(factor));
}

function returnFormula(currentPreCashFlow, previousPreCashFlow, initialInvest) {
    return currentPreCashFlow / (previousPreCashFlow ?? initialInvest);
}

function postCashFlowFormula(currentReturn, previousPostCashflow, initialInvest, cashFlow) {
    return currentReturn * (previousPostCashflow ?? initialInvest) + cashFlow
}

function simulatedMedianFormula(returnExpec, stDev, inflation, dateFactor) {
    const rand = Math.random();
    const norminv = jStat.normal.inv(rand, returnExpec, stDev) / 100

    return Math.exp(Math.log(1 + norminv - (inflation / 100)) * dateFactor - 0.5 * (stDev / 100) * (stDev / 100) * dateFactor)
}

function preCashFlowCalc(periods, initialInvest, sDev, returnExpec, inflation, dateFactor) {
    sDev = sDev / 100;
    const continuousMean = Math.log(1 + (returnExpec / 100 - inflation / 100));
    const sigPlus1 = jStat.normal.inv(SD_PLUS_1, 0, 1);
    const sigPlus2 = jStat.normal.inv(SD_PLUS_2, 0, 1);
    const sigMinus1 = jStat.normal.inv(SD_MINUS_1, 0, 1);
    const sigMinus2 = jStat.normal.inv(SD_MINUS_2, 0, 1);

    return [...Array(periods)].map((_, i) => {
        const sDevPlus2 = preCashFlowFormula(initialInvest, continuousMean, sDev, sigPlus2, i + 1, dateFactor);
        const sDevPlus1 = preCashFlowFormula(initialInvest, continuousMean, sDev, sigPlus1, i + 1, dateFactor);
        const sDevMinus1 = preCashFlowFormula(initialInvest, continuousMean, sDev, sigMinus1, i + 1, dateFactor);
        const sDevMinus2 = preCashFlowFormula(initialInvest, continuousMean, sDev, sigMinus2, i + 1, dateFactor);
        const median = preCashFlowFormula(initialInvest, continuousMean, sDev, 0, i + 1, dateFactor);

        return { sDevPlus2, sDevPlus1, median, sDevMinus1, sDevMinus2 };
    });
}

function returnsCalc(periods, preCashFlow, initialInvest) {
    return preCashFlow.map((currentPreCashFlow, i) => {
        const previousPreCashflow = i > 0 ? preCashFlow[i - 1] : 0;

        const sDevPlus2 = returnFormula(currentPreCashFlow.sDevPlus2, previousPreCashflow.sDevPlus2, initialInvest);
        const sDevPlus1 = returnFormula(currentPreCashFlow.sDevPlus1, previousPreCashflow.sDevPlus1, initialInvest);
        const sDevMinus1 = returnFormula(currentPreCashFlow.sDevMinus1, previousPreCashflow.sDevMinus1, initialInvest);
        const sDevMinus2 = returnFormula(currentPreCashFlow.sDevMinus2, previousPreCashflow.sDevMinus2, initialInvest);
        const median = returnFormula(currentPreCashFlow.median, previousPreCashflow.median, initialInvest);

        return { sDevPlus2, sDevPlus1, median, sDevMinus1, sDevMinus2 };
    });
}

function postCashFlowCalc(periods, returns, initialInvest, cashFlow) {
    const postCashFlow = [];
    let previousPostCashflow = 0;
    for (let i = 0; i < periods; i++) {
        let currentReturn = returns[i];

        if (i > 0) {
            previousPostCashflow = postCashFlow[i - 1];
        }

        let sDevPlus2 = postCashFlowFormula(currentReturn.sDevPlus2, previousPostCashflow.sDevPlus2, initialInvest, cashFlow);
        let sDevPlus1 = postCashFlowFormula(currentReturn.sDevPlus1, previousPostCashflow.sDevPlus1, initialInvest, cashFlow);
        let sDevMinus1 = postCashFlowFormula(currentReturn.sDevMinus1, previousPostCashflow.sDevMinus1, initialInvest, cashFlow);
        let sDevMinus2 = postCashFlowFormula(currentReturn.sDevMinus2, previousPostCashflow.sDevMinus2, initialInvest, cashFlow);
        let median = postCashFlowFormula(currentReturn.median, previousPostCashflow.median, initialInvest, cashFlow);

        postCashFlow.push({ sDevPlus2, sDevPlus1, median, sDevMinus1, sDevMinus2 });
    }
    return postCashFlow;
}

function simulatedMedianCalc(dateFactor, returnExpec, stDev, inflation, periods, cashFlow, initialInvest, rand) {
    const simulatedMedian = [];

    let previousMedian = initialInvest;
    for (let i = 0; i < periods; i++) {
        if (i > 0) {
            previousMedian = simulatedMedian[i - 1];
        }

        const percent = simulatedMedianFormula(returnExpec, stDev, inflation, (1 / dateFactor))
        const item = percent * previousMedian + cashFlow

        simulatedMedian.push(item);
    }

    return rand === 0 ? [] : simulatedMedian
}

function chartDataCalc(startDate, initialInvest, periods, postCashFlow, cashFlow, dateFactor, simulatedMedian) {
    const chartData = [];

    chartData.push({
        date: formatDate(0, startDate, dateFactor),
        sDevPlus2: initialInvest,
        sDevPlus1: initialInvest,
        sDevMinus1: initialInvest,
        sDevMinus2: initialInvest,
        median: initialInvest,
        simulatedMedian: initialInvest,
        totalInvested: parseFloat((initialInvest).toFixed(1)),
    });

    for (let i = 0; i < periods; i++) {
        let currentPostCashFlow = postCashFlow[i];
        let formattedDate = formatDate(i + 1, startDate, dateFactor);
        chartData.push({
            date: formattedDate,
            sDevPlus2: parseFloat((currentPostCashFlow.sDevPlus2).toFixed(1)),
            sDevPlus1: parseFloat((currentPostCashFlow.sDevPlus1).toFixed(1)),
            sDevMinus1: parseFloat((currentPostCashFlow.sDevMinus1).toFixed(1)),
            sDevMinus2: parseFloat(currentPostCashFlow.sDevMinus2.toFixed(1)),
            median: parseFloat(currentPostCashFlow.median.toFixed(1)),
            simulatedMedian: parseFloat(simulatedMedian[i]),
            totalInvested: parseFloat((initialInvest + (cashFlow * (i + 1))).toFixed(1)),
        });
    }

    return chartData;
}

function formatDate(i, startDate, dateFactor) {
    let date = new Date(startDate, 0);
    date.setMonth(date.getMonth() + (12 / dateFactor) * i + 1);

    let formattedDate = (date.getMonth()).toString().padStart(2, '0') + '/' + date.getFullYear().toString().slice(-2);
    return formattedDate;
}

export default performCalculation;