import React, { useEffect, useState } from 'react'
import { ComposedChart, Area, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import performCalculation from './Calculation'
import Constants from '../../shared/constants';

const Chart = ({
    initialInvestment, startDate, periods, returnExpect, stDev, inflation, cashFlow, isFormValid, dateFactor, rand
}) => {
    const [data, setData] = useState([]);
    const [finalData, setFinalData] = useState([]);

    useEffect(() => {
        if (!isFormValid) {
            return;
        }
        const timer = setTimeout(() => {
            const { chartData, finalData } = performCalculation(
                initialInvestment, startDate, periods, returnExpect, stDev, inflation, cashFlow, dateFactor, rand
            );
            setData(chartData);
            setFinalData(finalData);
        }, 500);
        return () => clearTimeout(timer);
    }, [
        initialInvestment, startDate, periods, returnExpect, stDev, inflation, cashFlow, isFormValid, dateFactor, rand
    ]);

    const filteredFinalData = {
        "SD-2": finalData.sDevMinus2,
        "SD-1": finalData.sDevMinus1,
        "Median": finalData.median,
        "Total Inv.": finalData.totalInvested,
        "SD+1": finalData.sDevPlus1,
        "SD+2": finalData.sDevPlus2,
    };

    function formatValue(value) {
        if (value >= 1000000) {
            return value ? `$${(value / 1000000).toFixed(2).toLocaleString()}m` : 0;
        } else if (value >= 10000) {
            return value ? `$${(value / 1000).toFixed(0).toLocaleString()}k` : 0;
        } else {
            return value ? `$${(value - 0).toFixed(0).toLocaleString()}` : 0;
        }
    }
    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip"
                    style={{
                        backgroundColor: '#F4F9FB',
                        padding: '10px',
                        borderRadius: '5px',
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
                    }}>
                    {payload.map((entry, index) => (
                        <p key={`item-${index}`} style={{ color: entry.color, fontWeight: '600' }}>
                            {`${entry.name}: ${formatValue(entry.value)}`}
                        </p>
                    ))}
                </div>
            );
        }
        return null;
    };

    return (
        <div>
            <div style={{ width: '100%', height: '600px' }}>
                <ResponsiveContainer width="100%" height="100%" >
                    <ComposedChart data={data}>
                        <CartesianGrid vertical={false} stroke="#ddd" />
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis dataKey="date" angle={-90} textAnchor="end" />
                        <YAxis tickFormatter={formatValue} />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend wrapperStyle={{ fontWeight: 'bold', paddingTop: '50px' }} />
                        <Area type="monotone" dataKey="sDevPlus2" name="SD+2" stroke="#ddd" fill="#ddd" fillOpacity={100} />
                        <Area type="monotone" dataKey="sDevPlus1" name="SD+1" stroke="#8be0da" fill="#8be0da" fillOpacity={100} />
                        <Area type="monotone" dataKey="sDevMinus1" name="SD-1" stroke="$ddd" fill="#ddd" fillOpacity={100} />
                        <Area type="monotone" dataKey="sDevMinus2" name="SD-2" stroke="#ddd" fill="#F4F9FB" fillOpacity={100} />
                        <Line type="monotone" dataKey="median" name="Median" stroke="#555" dot={false} strokeWidth={2} />
                        <Line type="monotone" dataKey="simulatedMedian" name="Simulation" stroke="#00f" dot={false} strokeWidth={2} />
                        <Line type="monotone" dataKey="totalInvested" name="Total Inv." stroke="#0080a0" dot={false} strokeWidth={2} strokeDasharray="5 5" />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>

            <h3 className="text-center py-2 mt-4">FINAL RESULTS</h3>
            <div className="achievement-2 py-2">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        {Object.entries(filteredFinalData).map(([key, value]) => (
                            <div className="col-lg-2 col-4" key={key}>
                                <div className="achievement-content d-flex justify-content-center align-items-center">
                                    <h2>{formatValue(value)} <span className={key + " light-text-1"}>{key}</span></h2>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <section className="map">
                <div className="map-block">
                    <div className="detail-content">
                        <h5>Summary</h5>
                        <p> Based on a starting investment of
                            <b> ${initialInvestment ? initialInvestment.toLocaleString() : 0}</b> in {startDate} and an&nbsp;
                            {parseInt(dateFactor) === Constants.DateFactor.MONTH ? "monthly" : parseInt(dateFactor) === Constants.DateFactor.QUARTER ? "quarlerty" : "annual"}&nbsp;
                            {cashFlow > 0 ? "contribution" : "withdrawal"} of&nbsp;
                            <b>${cashFlow ? cashFlow.toLocaleString() : 0}</b>, your investment is likely to be worth&nbsp;
                            <b>${finalData.median ? (finalData.median.toFixed(0) - 0).toLocaleString() : 0}</b> in&nbsp;
                            {startDate + periods}. This represents a total return of&nbsp;
                            <b>${finalData.median || finalData.totalInvested ? (finalData.median.toFixed(0) - finalData.totalInvested.toFixed(0)).toLocaleString() : 0}</b> over&nbsp;
                            <b>{periods}</b> years.
                        </p>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Chart
