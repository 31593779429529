import React, { useState, useEffect, useRef } from 'react';
import { useLocalStorage } from 'react-use';
import Chart from '../services/returnSight/Chart';
import NumberInput from '../components/NumberInput';
import SelectInput from '../components/SelectInput';
import Constants from '../shared/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesDown, faAnglesUp, faBan } from '@fortawesome/free-solid-svg-icons';
import { Collapse } from 'react-bootstrap';

const Home = () => {
  //control fields
  const [fade, setFade] = useState(false);
  const initialRender = useRef(true);
  const [isFormValid, setIsFormValid] = useState(false);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [rand, setRand] = useState(0);

  // input fields
  const [investment, setInitialInvestment] = useLocalStorage('initialInvestment', Constants.Investment.DEFAULT);
  const [startDate, setStartDate] = useLocalStorage('startDate', Constants.StartDate.DEFAULT);
  const [periods, setPeriods] = useLocalStorage('periods', Constants.Periods.DEFAULT);
  const [annualReturn, setReturnExpect] = useLocalStorage('returnExpect', Constants.AnnualReturn.DEFAULT);
  const [annualStDev, setStDev] = useLocalStorage('stDev', Constants.StDev.DEFAULT);
  const [inflation, setInflation] = useLocalStorage('inflation', Constants.Inflation.DEFAULT);
  const [cashFlow, setCashFlow] = useLocalStorage('cashFlow', Constants.CashFlow.DEFAULT);
  const [dateFactor, setDateFactor] = useLocalStorage('dateFactor', Constants.DateFactor.YEAR);

  // TODO: reset inflation checkbox
  // Define the resetFields function
  const resetFields = () => {
    setInitialInvestment(Constants.Investment.DEFAULT);
    setStartDate(Constants.StartDate.DEFAULT);
    setPeriods(Constants.Periods.DEFAULT);
    setReturnExpect(Constants.AnnualReturn.DEFAULT);
    setStDev(Constants.StDev.DEFAULT);
    setInflation(Constants.Inflation.DEFAULT);
    setCashFlow(Constants.CashFlow.DEFAULT);
    setDateFactor(Constants.DateFactor.YEAR);
  };

  useEffect(() => {
    const validateAllFields = () => {
      const currentYear = new Date().getFullYear()
      return (
        investment >= Constants.Investment.MIN && investment <= Constants.Investment.MAX &&
        startDate >= currentYear - Constants.StartDate.MIN && startDate <= currentYear + Constants.StartDate.MAX &&
        periods >= Constants.Periods.MIN && periods <= Constants.Periods.MAX &&
        annualReturn >= Constants.AnnualReturn.MIN && annualReturn <= Constants.AnnualReturn.MAX &&
        annualStDev >= Constants.StDev.MIN && annualStDev <= Constants.StDev.MAX &&
        inflation >= Constants.Inflation.MIN && inflation <= Constants.Inflation.MAX &&
        cashFlow >= Constants.CashFlow.MIN && cashFlow <= Constants.CashFlow.MAX
      );
    };
    setIsFormValid(validateAllFields());
  }, [
    investment, startDate, periods, annualReturn, annualStDev, inflation, cashFlow, dateFactor, rand
  ]);

  // Effect for handling the chart update logic
  useEffect(() => {
    if (!isFormValid) return;
  }, [isFormValid]);

  useEffect(() => {
    if (!isFormValid) return;

    let timeoutId;
    if (!initialRender.current) {
      setFade(true);
      timeoutId = setTimeout(() => {
        setFade(false);
      }, 1500);
    } else {
      initialRender.current = false;
    }

    return () => clearTimeout(timeoutId);
  }, [investment, startDate, periods, annualReturn, annualStDev, inflation, cashFlow, isFormValid, dateFactor, rand]);


  const toggleAdvancedOptions = (event) => {
    event.preventDefault();
    setShowAdvancedOptions(!showAdvancedOptions);
  };

  const runSimulations = (event) => {
    event.preventDefault();

    setRand(Math.random())
  };

  const clearSimulations = (event) => {
    event.preventDefault();

    setRand(0)
  };

  // TODO: when the user sets the inflation, set this back to
  const handleInputChange = (event) => {
    setInflation(event.target.checked ? Constants.Inflation.DEFAULT : 0);
  };

  return (
    <div>
      <div className="chart_updated_alert"
        style={{ display: isFormValid && fade ? 'block' : 'none' }}>
        CHART UPDATED
      </div>
      <form>
        <div className="row">
          <div className="col-md-4">
            <NumberInput id="initialInvestment" label="Initial Investment"
              value={investment}
              min={Constants.Investment.MIN} max={Constants.Investment.MAX}
              tooltip="What is your portfolio starting balance?"
              setValue={setInitialInvestment}
              allowNegative={false}
              decimalScale={2}
              prefix="$"
              thousandSeparator={true}
              inputMode="decimal"
            />

          </div>
          <div className="col-md-4">
            <NumberInput id="cashFlow"
              label={parseInt(dateFactor) === Constants.DateFactor.MONTH ?
                "Monthly Cash flow" :
                parseInt(dateFactor) === Constants.DateFactor.QUARTER ? "Quarlerty Cash flow" :
                  "Annual Cash flow"}
              value={cashFlow}
              min={Constants.CashFlow.MIN} max={Constants.CashFlow.MAX}
              setValue={setCashFlow}
              tooltip="How much are you adding to or taking from the portfolio? Use a negative number for withdrawals. "
              allowNegative={true}
              decimalScale={2}
              prefix="$"
              thousandSeparator={true}
              inputMode="text"
            />
          </div>
          <div className='col-md-4'>
            <NumberInput id="periods" label="Number of years"
              value={periods}
              min={Constants.Periods.MIN} max={Constants.Periods.MAX}
              tooltip="Tell us how long you want to see the analysis for."
              setValue={setPeriods}
              inputMode="numeric"
            />

          </div>
        </div>

        <Collapse in={showAdvancedOptions}>
          <div className="row" id="advanced-options">
            <div className="col-md-4">
              <NumberInput id="returnExpect" label="Return expectation"
                value={annualReturn}
                min={Constants.AnnualReturn.MIN} max={Constants.AnnualReturn.MAX}
                tooltip="Your expected portfolio return on an annual basis."
                tooltipLink="service9"
                setValue={setReturnExpect}
                decimalScale={2}
                inputMode="decimal"
              />

              <SelectInput
                id="dateFactor"
                label="Frequency"
                value={dateFactor}
                setValue={setDateFactor}
                tooltip="How frequently would you like to contribute or withdraw from the portfolio?"
                options={[
                  { label: 'Yearly', value: Constants.DateFactor.YEAR },
                  { label: 'Quarterly', value: Constants.DateFactor.QUARTER },
                  { label: 'Monthly', value: Constants.DateFactor.MONTH }
                ]}
              />

            </div>
            <div className="col-md-4">
              <NumberInput id="stDev" label="Standard Deviation"
                value={annualStDev}
                min={Constants.StDev.MIN} max={Constants.StDev.MAX}
                tooltip="The annual standard deviation of your portfolio. This is a tricky one, but really important for ReturnSight."
                tooltipLink="service4"
                setValue={setStDev}
                decimalScale={2}
                inputMode="decimal"
              />

              <NumberInput id="startDate" label="Start Date"
                value={startDate}
                min={new Date().getFullYear() - Constants.StDev.MIN}
                max={new Date().getFullYear() + Constants.StDev.MAX}
                tooltip="When would you like to start investing?"
                setValue={setStartDate}
                inputMode="numeric"
              />

            </div>
            <div className="col-md-4">
              <NumberInput id="inflation" label="Inflation (annual)"
                value={inflation}
                min={Constants.Inflation.MIN} max={Constants.Inflation.MAX}
                tooltip="What annual inflation do you expect? Not sure? A good rule of thumb is to use the long term average for your country."
                tooltipLink="service6"
                setValue={setInflation}
                decimalScale={2}
                inputMode="decimal"
              />

              <div className="row">
                <div className={rand === 0 ? 'col-12' : 'col-6'}>
                  <label className="form-label">&nbsp;</label>
                  <div className="input-group ">
                    <button className='form-control custom-button' onClick={runSimulations}
                    >{rand === 0 ? "RUN SIMULATION" : "RUN AGAIN"}</button>
                  </div>
                </div>
                <div className='col-6'>
                  {rand !== 0 &&
                    <div>
                      <label className="form-label">&nbsp;</label>
                      <div className="input-group">
                        <button className='form-control custom-button' onClick={clearSimulations}
                        >CLEAR</button>
                      </div>
                    </div>}
                </div>
              </div>
            </div>
          </div>
        </Collapse>

        <div className="row">
          <div className=" mt-2 mb-5" >
            <div className='border-top text-center pt-2 fw-bold' style={{ cursor: 'pointer' }} onClick={toggleAdvancedOptions}>
              {showAdvancedOptions ?
                <FontAwesomeIcon color='#34D4CD' icon={faAnglesUp} />
                : <FontAwesomeIcon color='#34D4CD' icon={faAnglesDown} />}&nbsp;ADVANCED OPTIONS&nbsp;
              {showAdvancedOptions ?
                <FontAwesomeIcon color='#34D4CD' icon={faAnglesUp} />
                : <FontAwesomeIcon color='#34D4CD' icon={faAnglesDown} />}
            </div>


            {/* <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexSwitchCheckDefault"
              onChange={handleInputChange} defaultChecked
            ></input>
            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">&nbsp;INFLATION</label>
          </div> */}
            {/* <div style={{ cursor: 'pointer' }} onClick={resetFields}>
            <FontAwesomeIcon color='#34D4CD' icon={faBan} /> RESET
          </div> */}
          </div>
        </div>




      </form >

      <Chart
        initialInvestment={investment}
        startDate={startDate}
        periods={periods}
        returnExpect={annualReturn}
        stDev={annualStDev}
        inflation={inflation}
        cashFlow={cashFlow}
        isFormValid={isFormValid}
        dateFactor={dateFactor}
        rand={rand}
      />

    </div >
  );
};

export default Home;